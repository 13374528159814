/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
import {
  ref, onMounted, getCurrentInstance, reactive, computed, onBeforeUnmount, watch
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers,
  minLength,
  maxLength,
  alpha
} from '@vuelidate/validators'
import {
  voucherDiscountUseCase
} from '@/domain/usecase'
import AdditionalParamsForm from '@/views/master/transactions/voucher-discount/form/AdditionalParamsForm.vue'
import moment from 'moment'
import PramsComponents from '@/views/master/transactions/voucher-discount/form/PramsComponents.vue'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import { Remote, remoteEnum } from '@/data/source/remote/remote'
import Repository from '@/data/repository'
import { Response } from '@/domain/entities'
import {
  environment
} from '@/utils'
// start add image camera=============================================================
import { compressImage } from '@/plugins/compressor'
import { useDropzone } from 'vue3-dropzone'
import { takePhoto } from '@/plugins/system/camera'
// end add image camera=============================================================

const modules = 'voucher'

export default {
  name: 'VoucherDiscountFormV2',
  components: {
    AdditionalParamsForm,
    PramsComponents
  },
  setup() {
    const app = getCurrentInstance()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const store = useStore()
    const route = useRoute() as any
    const router = useRouter()
    const isShowDialogParams = ref(false)
    const refFormAddParams = ref() as any
    const dataFormChoice = ref([]) as any
    const showAddPrams = ref(false)
    const decryptData = () => encryptDecriptMethods.decrypt(route.params.idVoucher)
    const idVoucher = ref(null)
    const isEdit = ref(false)
    const linkEnv = ref(environment.getApiBase())
    const codeGenerate = ref(null)
    const rules = {
      title: {
        required: helpers.withMessage('Judul Voucher harus diisi', required)
      },
      description: {
        required: helpers.withMessage('Deskripsi Voucher harus diisi', required)
      },
      type: {
        required: helpers.withMessage('Type Voucher harus diisi', required)
      }
    }
    const dataForm = reactive({
      title: computed({
        get: () => store.state[modules].Nama,
        set: (val) => {
          store.dispatch(`${modules}/setNama`, val)
        }
      }),
      description: computed({
        get: () => store.state[modules].Keterangan,
        set: (val) => {
          store.dispatch(`${modules}/setDescription`, val)
        }
      }),
      targetUser: computed({
        get: () => store.state[modules].GenerateRefCode,
        set: (val) => {
          if (val) {
            store.dispatch(`${modules}/setKode`, null)
          }
          store.dispatch(`${modules}/setGenerateReferalCode`, val)
        }
      }),
      priceType: computed({
        get: () => store.state[modules].IsMoney,
        set: (val) => {
          store.dispatch(`${modules}/setIsMoney`, val)
        }
      }),
      kode: computed({
        get: () => store.state[modules].Kode,
        set: (val) => {
          store.dispatch(`${modules}/setKode`, val)
        }
      }),
      maxPotongan: computed({
        get: () => store.state[modules].MaxPotongan,
        set: (val) => {
          store.dispatch(`${modules}/setMaxPotongan`, val)
        }
      }),
      discount: computed({
        get: () => store.state[modules].Discount,
        set: (val) => {
          store.dispatch(`${modules}/setDiscount`, val)
        }
      }),
      qty: computed({
        get: () => store.state[modules].Qty,
        set: (val) => {
          store.dispatch(`${modules}/setQty`, val)
        }
      }),
      useLimit: computed({
        get: () => store.state[modules].UseLimit,
        set: (val) => {
          if (val !== null) {
            store.dispatch(`${modules}/setUseLimit`, val)
          } else {
            store.dispatch(`${modules}/setUseLimit`, 0)
          }
        }
      }),
      poin: computed({
        get: () => store.state[modules].Poin,
        set: (val) => {
          if (val !== null) {
            store.dispatch(`${modules}/setPoin`, val)
          } else {
            store.dispatch(`${modules}/setPoin`, 0)
          }
        }
      }),
      isActive: computed({
        get: () => store.state[modules].IsActive,
        set: (val) => {
          store.dispatch(`${modules}/setIsActive`, val)
        }
      }),
      isHidden: computed({
        get: () => store.state[modules].IsHidden,
        set: (val) => {
          store.dispatch(`${modules}/setIsHidden`, val)
        }
      }),
      type: computed({
        get: () => store.state[modules].Type,
        set: (val) => {
          store.dispatch(`${modules}/setVoucherType`, val)
        }
      }),
      penggunaan: computed({
        get: () => store.state[modules].Penggunaan,
        set: (val) => {
          store.dispatch(`${modules}/setVoucherPenggunaan`, val)
        }
      })
    })
    const targetUserOption = ref([
      {
        label: 'Otomatis Generate',
        value: true
      },
      {
        label: 'Manual',
        value: false
      }
    ])
    const priceTypeOption = ref([{
      label: 'Persentase Diskon (%)',
      value: false
    },
    {
      label: 'Potongan Harga Tetap (Rp.)',
      value: true
    }])
    const listType = ref([
      {
        label: 'Pengiriman',
        value: 'Pengiriman',
        id: 0
      },
      {
        label: 'Barang',
        value: 'Barang',
        id: 1
      },
      {
        label: 'Gold',
        value: 'Gold',
        id: 2
      }
    ])
    const imgTemp = ref(null) as any
    const imgTempThumb = ref(null) as any
    const numberOfUserOption = ref([])
    const numberOfUser = ref(null)
    const submitted = ref(false)
    const tglHD = ref(moment().format('DD-MM-YYYY'))

    const v$ = useVuelidate(rules, dataForm)

    const setForm = (data: any) => {
      console.log('data', data)
      idVoucher.value = data.Id
      dataForm.title = data.Nama
      dataForm.description = data.Keterangan
      dataForm.targetUser = data.GenerateRefCode
      dataForm.kode = data.GenerateRefCode ? null : data.MsVoucherCodes[0].Kode
      codeGenerate.value = data.MsVoucherCodes[0].Kode
      dataForm.priceType = data.IsMoney
      dataForm.maxPotongan = data.MaxPotongan
      dataForm.discount = data.IsMoney ? data.Potongan : data.Persen
      dataForm.qty = data.Qty
      dataForm.useLimit = data.UseLimit
      dataForm.poin = data.Poin
      dataForm.isHidden = data.IsHidden
      dataForm.isActive = data.IsActive
      imgTemp.value = data.Filename
      imgTempThumb.value = data.Thumbnail
      // eslint-disable-next-line no-use-before-define
      dataForm.type = filterVoucherType(data.Type)
      dataForm.penggunaan = data.Penggunaan
    }

    const filterVoucherType = (type: any) => {
      const temp = listType.value.filter((x: any) => x.value === type)
      console.log('temp', temp)
      return temp[0].value
    }

    const isDisableForm = () => {
      if (dataForm.targetUser === true && isEdit.value === true && (route?.meta?.permission?.write || route?.meta?.permission?.update)) {
        return true
      }
      return false
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const funcResponse = (prams: any): Promise<Response> => new Repository(prams, null).getResult(false)

    const funcProcessGetUrl = async (urlFetch: any) => {
      const response = await funcResponse(new Remote(remoteEnum.get, urlFetch))
      const result = await response.result
      return result
    }

    const hoursConvert = (valHours: any) => {
      let hours
      if (valHours.toString().includes(' ')) {
        const arrDate = tglHD.value.split('-')
        hours = `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}T${moment(valHours).format('HH:mm:ss')}+07:00`
      } else {
        const arrDate = tglHD.value.split('-')
        hours = `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}T${valHours}:00+07:00`
      }
      return hours
    }

    const backPages = () => {
      router.push({
        name: 'master-voucher-diskon'
      })
    }

    const mappingListAdditionalParams = async (dataArr: any) => {
      console.log('dataArr', dataArr)
      const arr = '@Array'
      const hst = '@Host'
      for (let i = 0; i < dataArr.length; i++) {
        // dataArr.forEach(async (node: any, i: any) => {
        const node = dataArr[i]
        node.ResultFetch = null
        node.IsCheck = false
        node.ValueChoice = dataArr[i].Nilai
        node.showField = false
        if (node.VoucherParamType === 'TRANGE') {
          node.ValueChoice = node.ValueChoice.split(',') // .map((x: any) => hoursConvert(x))
        } else if (node.VoucherParamType === 'DRANGE') {
          node.ValueChoice = node.ValueChoice.split(',').map((x: any) => moment(x))
        } else if (node.VoucherParamType === 'NUMERIC') {
          node.ValueChoice = Number(node.ValueChoice)
        }
        if (node.VoucherParamUrlFetch !== null) {
          if (node.VoucherParamUrlFetch.includes(hst)) {
            let hasil = null
            /* eslint-disable-next-line no-await-in-loop */
            hasil = await funcProcessGetUrl(node.VoucherParamUrlFetch.replace(hst, '')) as any
            node.ResultFetch = hasil.map((x: any) => {
              x.label = node.Kode === 'HR' ? x.Kode : x.Nama
              x.value = x.Id
              return x
            })
          }
          if (node.VoucherParamUrlFetch.includes(arr)) {
            node.ResultFetch = JSON.parse(node.VoucherParamUrlFetch.replace(arr, '')).map((x: any) => ({
              label: x,
              value: x
            }))
          }
          node.ValueChoice = node.ValueChoice.split(',')
        }
        node.showField = true
      }
      return dataArr
    }

    const getAllData = async () => {
      store.dispatch('showLoading')
      // console.log('aaaaaaaaaaaaa')
      // const response = await voucherDiscountUseCase.getVoucherHd({
      //   custom: `Id eq ${Number(decryptData())}`,
      //   expand: '&$expand=MsVoucherCodes($select=Kode),MsVoucherDetails($select=Id,Nilai,VoucherHdId,VoucherPramId;$expand=VoucherPram)'
      // })
      const response = await voucherDiscountUseCase.getSingleVoucherHdV2(Number(decryptData()))
      if (response.error) {
        $toast.add({
          severity: 'error', detail: 'Gagal mengambil data detail voucher', group: 'bc', life: 1500
        })
        // backPages()
      } else {
        const setData = await response.result
        isEdit.value = await true
        const responseDetails = await voucherDiscountUseCase.getVoucherHdV2Detail(Number(decryptData()))
        console.log('response', responseDetails.result)

        if (await responseDetails.result.length > 0) {
          const tempDataFormChoice = await mappingListAdditionalParams(responseDetails.result)
          console.log('tempDataFormChoice', tempDataFormChoice)
          dataFormChoice.value = await tempDataFormChoice.map((x: any) => {
            // const voucherPram = x.VoucherPram
            console.log('voucherPram', x)
            return {
              IdVoucherDetails: x.Id,
              // ErrorMessage: voucherPram.ErrorMessage,
              Grup: x.ValueGrup,
              // Guid: voucherPram.Guid,
              Id: x.VoucherParamId,
              IsCheck: false,
              // Keterangan: voucherPram.Keterangan,
              Kode: x.VoucherParamCode,
              Nama: x.VoucherparamName,
              // PramFunc: voucherPram.PramFunc,
              ResultFetch: x.ResultFetch,
              Tipe: x.VoucherParamType,
              UrlFetch: x.VoucherParamUrlFetch,
              ValueChoice: x.ValueChoice,
              showField: x.showField
            }
          })
        }
        // console.log('iki data', setData)
        await setForm(setData)
        store.dispatch('hideLoading')
      }
    }

    const addNewPrams = () => {
      isShowDialogParams.value = true
    }

    // start image camera=============================================================
    const isDragActive = ref(false)
    const image = computed({
      get: () => store.state[modules].Gambar,
      set: (val) => {
        store.dispatch(`${modules}/setGambar`, val)
      }
    })

    const pushTempImage = async (item: any, from: any = '') => {
      let file = item
      if (from === 'camera') {
        // file = new File([item], 'Name', {
        //   lastModified: moment().unix(),
        //   type: item.type,
        // })
        item = item.image
      } else {
        // eslint-disable-next-line no-await-in-loop
        file = await compressImage(file)
        file = new File([file], file.name)
        console.log('file to compress', file)
      }
      const img = image.value as any
      if (img.src === null) {
        image.value = {
          src: URL.createObjectURL(item),
          image: file
        }
      }
    }

    const resetImage = () => {
      image.value.src = null
      image.value.image = null
    }

    function onDrop(acceptFiles: any, rejectReasons: any) {
      if (acceptFiles[0].type.includes('image')) {
        pushTempImage(acceptFiles[0])
      } else {
        $toast.add({
          severity: 'error', detail: 'Hanya support tipe file gambar (png/jpg/jpeg)', group: 'bc', life: 1500
        })
      }
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop })
    const showCamera = async () => {
      const response = await takePhoto() as any
      console.log('response', JSON.stringify(response))
      pushTempImage(response, 'camera')
    }

    const proccessUploadFile = async () => {
      const item = image.value
      if (item.src !== null) {
        const formData = new FormData()
        // const senderId = props.id
        const img = item.image
        // formData.append('IdVoucher', idVoucher)
        if (img.from === 'camera') {
          formData.append('file', img.image, `${img.name}.${img.ext}`)
        } else {
          formData.append('file', img)
        }
        const response = await voucherDiscountUseCase.submitFileVoucherHd(formData)
        // console.log('response upload =>', response)
        if (response.error) {
          $toast.add({
            severity: 'error', detail: response.message, group: 'bc', life: 3000
          })
          return null
        } else {
          return response.result
        }
      } return null
    }
    // end add image camera=============================================================

    const convertTime = (val: any) => {
      // console.log('masukk', val)
      if (val.Tipe === 'DRANGE') {
        return val.ValueChoice.map((x: any) => moment(x).format('YYYY-MM-DD'))
        // eslint-disable-next-line brace-style
      } else if (val.Tipe === 'TRANGE') {
        return val.ValueChoice.map((x: any) => moment(x).format('HH:mm'))
      } else {
        return val.ValueChoice
      }
    }

    const typeObj = (val: any) => {
      // console.log(`lklk ar => ${Array.isArray(val)} =>`, val)
      if (typeof val === 'object' || Array.isArray(val)) {
        // console.log('is arr', val)
        return val.join(',')
      }
      return val
    }

    const conditionalUpload = (imgTmr: any, updFl: any, str: any) => {
      if (imgTmr) {
        return imgTmr
      } else if (updFl) {
        if (str === 'file') {
          return updFl.Filename
        } else if (str === 'thumb') {
          return updFl.Thumbnail
        }
      } else {
        return null
      }
    }

    const prosesSubmit = async () => {
      // console.log('json', JSON.stringify(store.state[modules]))
      const dataLocal = store.state[modules]
      $confirm.require({
        header: 'Voucher Diskon',
        message: 'Pastikan Semua Form Diisi dengan benar',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const resUpload = await proccessUploadFile() as any
          const dataStore = {
            Kode: dataLocal.Kode,
            Nama: dataLocal.Nama,
            Keterangan: dataLocal.Keterangan,
            Persen: !dataLocal.IsMoney ? dataLocal.Discount : null,
            Potongan: dataLocal.IsMoney ? dataLocal.Discount : null,
            MaxPotongan: dataLocal.MaxPotongan,
            Qty: dataLocal.Qty,
            UseLimit: dataLocal.UseLimit,
            // Penggunaan: null,
            Filename: conditionalUpload(imgTemp.value, resUpload, 'file'),
            Thumbnail: conditionalUpload(imgTempThumb.value, resUpload, 'thumb'),
            Poin: dataLocal.Poin,
            GenerateRefCode: dataLocal.GenerateRefCode,
            IsMoney: dataLocal.IsMoney,
            IsActive: dataLocal.IsActive,
            IsHidden: dataLocal.IsHidden,
            Type: dataLocal.Type,
            /* eslint-disable-next-line */
            MsVoucherDetails: dataFormChoice.value.map((x: any) => {
              if (x.Tipe === 'TRANGE') {
                x.ValueChoice = x.ValueChoice.map((v: any) => hoursConvert(v))
              }
              return {
                VoucherPramId: x.Id,
                Nilai: typeObj(convertTime(x)).toString()
              }
            })
          }
          const response = await voucherDiscountUseCase.submitVoucherPram(idVoucher.value, dataStore)
          // console.log('quality', response)
          if (!response.error) {
            if (idVoucher.value) {
              $toast.add({
                severity: 'success', detail: response.result.detail ?? response.result.Detail, group: 'bc', life: 3000
              })
            } else {
              $toast.add({
                severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
              })
            }
            backPages()
          } else {
            $toast.add({
              severity: 'error', detail: response.message, group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          }
        }
      })
    }

    const closeModalAddParams = () => {
      isShowDialogParams.value = false
    }

    const submitAdditionalParams = () => {
      const canSubmit = refFormAddParams.value.submitReturn()
      if (canSubmit.length > 0) {
        const isValidate = refFormAddParams.value.checkIsValidate()
        if (isValidate) {
          closeModalAddParams()
          refFormAddParams.value.submitValue()
        } else {
          $toast.add({
            severity: 'error', detail: 'Pastikan Tanggal Aktif menggunakan range', group: 'bc', life: 3000
          })
        }
        // refFormAddParams.value.updateValueForm()
      } else {
        $toast.add({
          severity: 'error', detail: 'Pastikan form diisi dengan benar', group: 'bc', life: 3000
        })
      }
    }
    const sendPreferredData = async (valSubmit: any) => {
      // dataFormChoice.value = null
      // showAddPrams.value = false
      if (valSubmit) {
        dataFormChoice.value = await valSubmit
        showAddPrams.value = true
      }
      // store.dispatch(`${modules}/setVoucherDetails`, dataSubmit)
    }
    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      prosesSubmit()
    }

    const prosesDelete = async () => {
      const response = await voucherDiscountUseCase.deleteVoucherDiscount(Number(idVoucher.value))
      // console.log('okeay response', response)
      if (!response.error) {
        $toast.add({
          severity: 'success', detail: response.result.detail ?? response.result.Detail, group: 'bc', life: 3000
        })
        backPages()
      } else {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      }
    }

    const deleteVoucher = () => {
      $confirm.require({
        header: 'Hapus Voucher',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          prosesDelete()
        }
      })
    }

    onMounted(() => {
      if (route.params.idVoucher) {
        getAllData()
      }
    })

    onBeforeUnmount(() => {
      store.dispatch(`${modules}/setDefaultVoucher`)
    })
    return {
      getAllData,
      route,
      router,
      priceTypeOption,
      targetUserOption,
      numberOfUserOption,
      numberOfUser,
      dataForm,
      submitted,
      rules,
      v$,
      submitData,
      isShowDialogParams,
      addNewPrams,
      closeModalAddParams,
      submitAdditionalParams,
      refFormAddParams,
      sendPreferredData,
      dataFormChoice,
      backPages,
      showAddPrams,
      isEdit,
      imgTemp,
      linkEnv,
      codeGenerate,
      isDisableForm,
      deleteVoucher,
      idVoucher,
      // start camera
      resetImage,
      showCamera,
      getRootProps,
      getInputProps,
      proccessUploadFile,
      isDragActive,
      image,
      listType
      // end camera
    }
  }
}
